function initializeFlashMessages(container) {
  if (!container) return;

  const closeButtons = container.querySelectorAll(".flash-close-button");
  const timeoutDuration = 5000;

  setTimeout(() => {
    container.querySelectorAll(".alert").forEach((alert) => {
      fadeOut(alert, () => {
        if (alert && alert.parentNode) {
          alert.parentNode.removeChild(alert);

          if (container.querySelectorAll(".alert").length === 0 && !container.classList.contains('saq-player-flash-messages')) {
            if (container.parentNode) {
              container.parentNode.removeChild(container);
            }
          }
        }
      });
    });
  }, timeoutDuration);

  closeButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const flashMessageDiv = button.closest(".alert");
      if (flashMessageDiv) {
        fadeOut(flashMessageDiv, () => {
          if (flashMessageDiv.parentNode) {
            flashMessageDiv.parentNode.removeChild(flashMessageDiv);

            if (container.querySelectorAll(".alert").length === 0) {
              if (container.parentNode) {
                container.parentNode.removeChild(container);
              }
            }
          }
        });
      }
    });
  });
}

function fadeOut(element, callback) {
  element.style.transition = "opacity 0.5s ease";
  element.style.opacity = 0;

  setTimeout(() => {
    if (callback) callback();
  }, 500);
}

document.addEventListener("turbo:load", () => {
  const flashMessagesContainer = document.getElementById("flash-messages");
  initializeFlashMessages(flashMessagesContainer);
});

document.addEventListener("turbo:before-stream-render", (event) => {
  const targetId = event.target.getAttribute("target");
  if (targetId === "flash-messages" || targetId === "saq-player-flash-messages") {
    setTimeout(() => {
      const updatedFlashMessages = document.getElementById(targetId);
      initializeFlashMessages(updatedFlashMessages);
    }, 10);
  }
});
