import datepickerFactory from 'jquery-datepicker';
datepickerFactory($)

function setUpDatePickers() {
  $('.datepicker').datepicker({
    dateFormat: "M d, yy",
    // June 1, 2017 was the launch date.
    minDate: new Date(2017, 6 - 1, 1),
    beforeShow: function (input, inst) {
      setTimeout(function () {
        inst.dpDiv.css({
          top: $(".datepicker").offset().top + 35,
          left: $(".datepicker").offset().left
        });
      }, 0);
    }
  })
  $('.studentdatepicker').datepicker({
    dateFormat: "M d, yy",
    minDate: new Date(),
  });
  $('.customcoursedatepicker1').datepicker({
    dateFormat: "M d, yy",
    minDate: new Date(),
  });
  $('.customcoursedatepicker2').datepicker({
    dateFormat: "M d, yy",
    minDate: new Date(),
  });

  $('.report-dashboard-datepicker1').datepicker({
    dateFormat: "M d, yy",
    onSelect: function() {
        $("#date_changed").click();
    }
  });
  $('.report-dashboard-datepicker2').datepicker({
    dateFormat: "M d, yy",
    onSelect: function() {
        $("#date_changed").click();
    }
  });
  $('.user-profile-datepicker').datepicker({
    dateFormat: "mm-dd-yy",
    beforeShow: function(input) {
      let fieldId = $(input).attr("id");
      // Set minDate and maxDate dynamically when datepicker opens
      if (fieldId === "registration_form_end_date") {
        let startDate = $("#registration_form_start_date").datepicker("getDate");
        if (startDate) {
          $(this).datepicker("option", "minDate", startDate);
        }
      }

      if (fieldId === "registration_form_start_date") {
        let endDate = $("#registration_form_end_date").datepicker("getDate");
        if (endDate) {
          $(this).datepicker("option", "maxDate", endDate);
        }
      }
    },
    onSelect: function() {
      let fieldId = $(this).attr("id");
      if ($("#registration_form_start_date").hasClass("validate-date") && fieldId === "registration_form_start_date") {
        let startDate = $(this).datepicker("getDate");
        $("#registration_form_end_date").datepicker("option", "minDate", startDate || null);
      }

      if ($("#registration_form_end_date").hasClass("validate-date") && fieldId === "registration_form_end_date") {
        let endDate = $(this).datepicker("getDate");
        $("#registration_form_start_date").datepicker("option", "maxDate", endDate || null);
      }

      $("#date_changed").click();
    },
    onClose: function() {
      // if registration_form_start_date is empty, set minDate to null for registration_form_end_date
      if ($("#registration_form_start_date").val() === "") {
        $("#registration_form_end_date").datepicker("option", "minDate", null);
      }
      // if registration_form_end_date is empty, set maxDate to null for registration_form_start_date
      if ($("#registration_form_end_date").val() === "") {
        $("#registration_form_start_date").datepicker("option", "maxDate", null);
      }
      $("#date_changed").click();
    }
  });
  $('.exam-academic-start-date-picker').datepicker({
    numberOfMonths: 1,
    dateFormat: "mm-dd-yy",
    onSelect: function() {
      $("#date_changed").click();
    }
  });
  $('.exam-academic-end-date-picker').datepicker({
    numberOfMonths: 1,
    dateFormat: "mm-dd-yy",
    onSelect: function() {
      $("#date_changed").click();
    }
  });
  $('.academic-start-date-picker-edit').datepicker({
    numberOfMonths: 1,
    dateFormat: "mm-dd-yy",
    onSelect: function() {
      $("#date_changed").click();
    }
  });
  $('.academic-end-date-picker-edit').datepicker({
    numberOfMonths: 1,
    dateFormat: "mm-dd-yy",
    onSelect: function() {
      $("#date_changed").click();
    }
  });
  $('.educator-dashboard-datepicker').datepicker({
    dateFormat: "M d, yy",
    onSelect: function(dateText, inst) {
      const element = $(this)[0]; // Get the raw DOM element
      element.value = dateText; // Update the value explicitly
  
      // Manually trigger the Stimulus action
      const event = new Event("change", { bubbles: true });
      element.dispatchEvent(event);
    },
    beforeShow: function(input, inst) {
      // Add a custom class to the datepicker popup
      setTimeout(function () {
        $('#ui-datepicker-div').addClass('educator-dashboard-datepicker-popup');
      }, 0);
    }
  })
  $('.superadmin-dashboard-datepicker').datepicker({
    dateFormat: "M d, yy",
    onSelect: function(dateText, inst) {
      const element = $(this)[0]; // Get the raw DOM element
      element.value = dateText; // Update the value explicitly
  
      // Manually trigger the Stimulus action
      const event = new Event("change", { bubbles: true });
      element.dispatchEvent(event);
    },
    beforeShow: function(input, inst) {
      // Add a custom class to the datepicker popup
      setTimeout(function () {
        $('#ui-datepicker-div').addClass('superadmin-dashboard-datepicker-popup');
      }, 0);
    }
  })

}

$(document).ready(setUpDatePickers);
$(document).on('turbo:load', setUpDatePickers);
