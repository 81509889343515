import "../controllers";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "@hotwired/turbo"

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import $ from "jquery"

import * as EasyMDE from 'easymde';
window.EasyMDE = EasyMDE;

global.Rails = Rails
Rails.start()
ActiveStorage.start()

import 'application/custom_confirm_box'
import 'application/datepicker'
import 'application/disabling_checkbox'
import 'application/disabling_other_select'
import 'application/disabling_title_select'
import 'application/infinite_scroll'
import 'application/intl_tel_input'
import 'application/list_search'
import 'application/list_sort'
import 'application/navigation_menu'
import 'application/report_tab'
import 'application/setConfirmFromSelect'
import 'application/show_calendar'
import 'application/usage_report'
import 'application/user_panel_tab'
import 'application/jquery.tablednd'
import "application/track_time.js"
import "application/analytics.js"
import "application/registration_form.js"
import "application/flash_messages.js"
import "application/tab_navigation.js"

import "controllers"
